<template>
  <qrcode-vue />
</template>
<script>
  import QrcodeVue from 'qrcode.vue'

  export default {
    components: {
      QrcodeVue,
    },
    setup(props){}
  }
</script>
